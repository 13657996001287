/** @jsxImportSource @emotion/react */
/* global fbq */
/* global gtag_report_conversion */
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import * as styles from "./styles";
import GetOnAppleAppStore from "../assets/GetOnAppleAppStore";
import GetOnGooglePlayStore from "../assets/GetOnGooglePlayStore.png";
import genie_logo from "../assets/genie_logo.png";
import feature from "../assets/feature.jpg";

const APPLE_DOWNLOAD_URL =
  "https://apple.co/3XQK3jS";

const MobileLanding = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deviceType = queryParams.get("deviceType");

  function setVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", setVhProperty);
  setVhProperty(); // Set initially on load

  return (
    <div css={styles.Container}>
      <div css={styles.Navbar}>
        <button
          type="button"
          onClick={() => {
            window.open("https://genie.gg", "_blank");
          }}
        >
          <img css={styles.Logo} src={genie_logo} alt="genie_logo" />
        </button>
      </div>
      <div css={styles.Card}>
        {!isMobile && (
          <img css={[styles.FeatureImage]} src={feature} alt="feature" />
        )}

        <span css={styles.Title}>
          Your kid’s a hero. Get them a{" "}
          <span css={styles.TextMulticolor}>sidekick</span>.
        </span>
        {deviceType !== "android" ? (
          <button
            type="button"
            css={styles.DownlodButton}
            onClick={() => {
              if (typeof fbq !== "undefined") {
                fbq("track", "Lead", {});
              }
              if (typeof gtag_report_conversion !== "undefined") {
                gtag_report_conversion();
              }
              window.open(APPLE_DOWNLOAD_URL, "_blank");
            }}
          >
            <GetOnAppleAppStore />
          </button>
        ) : (
          <button
            type="button"
            css={styles.DownlodButton}
            onClick={() => {
              window.open(APPLE_DOWNLOAD_URL, "_blank");
            }}
          >
            <img
              css={styles.GooglePlayStore}
              src={GetOnGooglePlayStore}
              alt="hero"
            />
          </button>
        )}
        {isMobile && (
          <img css={styles.FeatureImage} src={feature} alt="feature" />
        )}
      </div>
    </div>
  );
};

export default MobileLanding;
