import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MobileLanding from "./components/MobileLanding";
import GlobalStyles from "./GlobalStyles";

const routes = (
  <>
    <GlobalStyles />
    <Router>
      <Routes>
        <Route path="/" element={<MobileLanding />} />
      </Routes>
    </Router>
  </>
);

export default routes;
