const GetOnAppleAppStore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="180"
    height="61"
    viewBox="0 0 180 61"
    fill="none"
  >
    <g clipPath="url(#clip0_1_9)">
      <path
        d="M165.666 0.000195547H14.3422C13.7906 0.000195547 13.2456 0.000195547 12.6955 0.00320397C12.235 0.00621239 11.7781 0.0149519 11.3132 0.0223074C10.3031 0.0341934 9.29543 0.123054 8.29888 0.288117C7.30373 0.456773 6.33976 0.774737 5.43956 1.23126C4.54046 1.69164 3.71893 2.28986 3.00477 3.00421C2.28686 3.71655 1.68842 4.53993 1.23248 5.44267C0.775271 6.34362 0.458244 7.30906 0.292343 8.30567C0.124866 9.30101 0.0347448 10.3078 0.0227888 11.3171C0.00882972 11.7783 0.00735559 12.241 0 12.7023V47.471C0.00735559 47.938 0.00882972 48.3905 0.0227888 48.8577C0.0347485 49.8669 0.12487 50.8737 0.292343 51.869C0.457785 52.8662 0.774831 53.8322 1.23248 54.7335C1.68821 55.6333 2.28673 56.4533 3.00477 57.1617C3.71622 57.8792 4.53828 58.4779 5.43956 58.9347C6.33975 59.3925 7.30362 59.7123 8.29888 59.8836C9.2956 60.0473 10.3032 60.1362 11.3132 60.1495C11.7781 60.1598 12.235 60.1656 12.6955 60.1656C13.2456 60.1686 13.7906 60.1686 14.3422 60.1686H165.666C166.207 60.1686 166.756 60.1686 167.296 60.1656C167.755 60.1656 168.225 60.1598 168.683 60.1495C169.691 60.1369 170.697 60.048 171.692 59.8836C172.69 59.7111 173.657 59.3913 174.562 58.9347C175.462 58.4776 176.284 57.879 176.995 57.1617C177.711 56.4506 178.311 55.6311 178.772 54.7335C179.226 53.8315 179.54 52.8656 179.703 51.869C179.871 50.8736 179.964 49.867 179.982 48.8577C179.988 48.3905 179.988 47.938 179.988 47.471C180 46.9245 180 46.381 180 45.8257V14.3445C180 13.7937 180 13.2472 179.988 12.7023C179.988 12.241 179.988 11.7783 179.982 11.317C179.964 10.3077 179.871 9.30109 179.703 8.30561C179.54 7.30958 179.226 6.34421 178.772 5.44261C177.843 3.63316 176.371 2.16028 174.562 1.23112C173.657 0.775718 172.69 0.457837 171.692 0.287981C170.697 0.122191 169.691 0.0332999 168.683 0.0220969C168.225 0.0147563 167.755 0.00594163 167.296 0.00300842C166.756 0 166.207 0.000195547 165.666 0.000195547Z"
        fill="#A6A6A6"
      />
      <path
        d="M12.7028 58.8523C12.2445 58.8523 11.7973 58.8464 11.3425 58.8362C10.4006 58.8239 9.46082 58.7419 8.53096 58.5908C7.66392 58.4415 6.82399 58.1637 6.03888 57.7667C5.26096 57.3729 4.55144 56.8566 3.93749 56.2375C3.31466 55.6257 2.79612 54.9161 2.40243 54.1369C2.0044 53.3525 1.72894 52.5119 1.58564 51.6441C1.4309 50.7116 1.34717 49.7688 1.33519 48.8237C1.32566 48.5064 1.31317 47.4502 1.31317 47.4502V12.7021C1.31317 12.7021 1.32647 11.662 1.33527 11.3565C1.34674 10.4129 1.42998 9.4715 1.58426 8.5405C1.72782 7.67035 2.00349 6.82724 2.40174 6.04035C2.79398 5.26165 3.30964 4.55153 3.92872 3.93754C4.54711 3.31772 5.2589 2.79874 6.03814 2.39953C6.82145 2.00375 7.65979 1.72794 8.52508 1.58133C9.45799 1.42875 10.401 1.34626 11.3462 1.33455L12.7035 1.31618H167.289L168.663 1.33529C169.599 1.34642 170.534 1.42817 171.458 1.57986C172.332 1.7283 173.179 2.00604 173.971 2.40394C175.533 3.20846 176.803 4.48129 177.604 6.04403C177.996 6.82547 178.267 7.66171 178.409 8.52435C178.565 9.46298 178.653 10.4118 178.671 11.3631C178.675 11.7891 178.675 12.2467 178.675 12.7021C178.687 13.2661 178.687 13.803 178.687 14.3444V45.8255C178.687 46.372 178.687 46.9052 178.675 47.4428C178.675 47.932 178.675 48.3801 178.669 48.8413C178.652 49.7757 178.566 50.7075 178.412 51.6293C178.272 52.5034 177.998 53.3506 177.6 54.1414C177.203 54.9122 176.688 55.6156 176.072 56.2257C175.458 56.8482 174.747 57.3675 173.967 57.7638C173.177 58.1639 172.331 58.4427 171.458 58.5908C170.528 58.7427 169.588 58.8247 168.646 58.8362C168.206 58.8464 167.744 58.8523 167.296 58.8523L165.666 58.8553L12.7028 58.8523Z"
        fill="black"
      />
      <path
        d="M37.2576 30.5365C37.2738 29.281 37.6073 28.05 38.227 26.958C38.8468 25.866 39.7327 24.9485 40.8024 24.2909C40.1229 23.3205 39.2264 22.5218 38.1843 21.9584C37.1421 21.395 35.9829 21.0824 34.7988 21.0453C32.2729 20.7802 29.8241 22.5568 28.5369 22.5568C27.2249 22.5568 25.2431 21.0717 23.109 21.1156C21.7286 21.1602 20.3833 21.5616 19.2042 22.2807C18.0251 22.9998 17.0524 24.012 16.3808 25.2189C13.4716 30.2556 15.6416 37.6579 18.4283 41.7292C19.8226 43.7228 21.4521 45.9497 23.5842 45.8707C25.6706 45.7842 26.4499 44.5403 28.9684 44.5403C31.4635 44.5403 32.1946 45.8707 34.37 45.8205C36.6088 45.7842 38.0194 43.8181 39.3647 41.8056C40.3665 40.3851 41.1374 38.8151 41.6488 37.1538C40.3481 36.6037 39.2381 35.6828 38.4572 34.5061C37.6763 33.3293 37.2591 31.9488 37.2576 30.5365Z"
        fill="white"
      />
      <path
        d="M33.1486 18.3678C34.3694 16.9023 34.9707 15.0188 34.8251 13.1171C32.9601 13.313 31.2374 14.2043 30.0002 15.6135C29.3953 16.302 28.932 17.1029 28.6368 17.9704C28.3417 18.838 28.2203 19.7553 28.2799 20.6698C29.2127 20.6794 30.1355 20.4772 30.9789 20.0784C31.8222 19.6797 32.5641 19.0948 33.1486 18.3678Z"
        fill="white"
      />
      <path
        d="M63.6315 40.8238H56.5115L54.8017 45.8726H51.7859L58.5299 27.1933H61.6631L68.4071 45.8726H65.3399L63.6315 40.8238ZM57.2489 38.494H62.8927L60.1105 30.3002H60.0327L57.2489 38.494Z"
        fill="white"
      />
      <path
        d="M82.9718 39.064C82.9718 43.296 80.7067 46.0151 77.2884 46.0151C76.4225 46.0604 75.5614 45.8609 74.8035 45.4395C74.0457 45.0181 73.4219 44.3918 73.0035 43.6324H72.9388V50.3778H70.1434V32.2539H72.8492V34.519H72.9006C73.3383 33.7632 73.9727 33.1402 74.7363 32.7162C75.4998 32.2923 76.3641 32.0832 77.237 32.1114C80.6934 32.1114 82.9718 34.8436 82.9718 39.064ZM80.0985 39.064C80.0985 36.3067 78.6736 34.494 76.4996 34.494C74.3637 34.494 72.9271 36.3449 72.9271 39.064C72.9271 41.808 74.3637 43.6456 76.4996 43.6456C78.6736 43.6456 80.0985 41.8462 80.0985 39.064Z"
        fill="white"
      />
      <path
        d="M97.9611 39.064C97.9611 43.296 95.6959 46.0151 92.2777 46.0151C91.4118 46.0604 90.5506 45.8609 89.7928 45.4395C89.035 45.0181 88.4112 44.3918 87.9927 43.6324H87.9281V50.3778H85.1326V32.2539H87.8384V34.519H87.8898C88.3275 33.7632 88.9619 33.1402 89.7255 32.7162C90.489 32.2923 91.3533 32.0832 92.2262 32.1114C95.6827 32.1114 97.9611 34.8436 97.9611 39.064ZM95.0878 39.064C95.0878 36.3067 93.6629 34.494 91.4888 34.494C89.353 34.494 87.9163 36.3449 87.9163 39.064C87.9163 41.808 89.353 43.6456 91.4888 43.6456C93.6629 43.6456 95.0878 41.8462 95.0878 39.064Z"
        fill="white"
      />
      <path
        d="M107.868 40.6681C108.075 42.5204 109.874 43.7366 112.333 43.7366C114.69 43.7366 116.385 42.5204 116.385 40.8501C116.385 39.4003 115.362 38.5321 112.941 37.9372L110.521 37.354C107.091 36.5255 105.498 34.9214 105.498 32.3184C105.498 29.0955 108.307 26.8818 112.295 26.8818C116.242 26.8818 118.948 29.0955 119.039 32.3184H116.217C116.048 30.4543 114.507 29.3291 112.255 29.3291C110.004 29.3291 108.463 30.4676 108.463 32.1245C108.463 33.4451 109.447 34.2222 111.854 34.8171L113.912 35.3224C117.745 36.2287 119.337 37.7682 119.337 40.5005C119.337 43.9951 116.554 46.1839 112.126 46.1839C107.984 46.1839 105.187 44.0465 105.006 40.6679L107.868 40.6681Z"
        fill="white"
      />
      <path
        d="M125.37 29.031V32.2539H127.96V34.4676H125.37V41.9754C125.37 43.1418 125.889 43.6853 127.027 43.6853C127.335 43.6799 127.642 43.6583 127.947 43.6206V45.8211C127.435 45.9167 126.915 45.96 126.394 45.9504C123.637 45.9504 122.562 44.9147 122.562 42.2736V34.4676H120.581V32.2539H122.562V29.031H125.37Z"
        fill="white"
      />
      <path
        d="M129.46 39.064C129.46 34.779 131.984 32.0864 135.919 32.0864C139.868 32.0864 142.379 34.779 142.379 39.064C142.379 43.3607 139.881 46.0415 135.919 46.0415C131.959 46.0415 129.46 43.3607 129.46 39.064ZM139.531 39.064C139.531 36.1246 138.184 34.3897 135.919 34.3897C133.654 34.3897 132.308 36.1378 132.308 39.064C132.308 42.0151 133.654 43.7367 135.919 43.7367C138.184 43.7367 139.531 42.0151 139.531 39.064Z"
        fill="white"
      />
      <path
        d="M144.684 32.2539H147.35V34.5719H147.415C147.595 33.8479 148.019 33.2081 148.616 32.76C149.213 32.312 149.945 32.0829 150.691 32.1114C151.013 32.1102 151.334 32.1452 151.649 32.2157V34.8304C151.242 34.7061 150.818 34.6491 150.393 34.6615C149.986 34.645 149.582 34.7166 149.206 34.8714C148.83 35.0261 148.492 35.2604 148.215 35.5581C147.939 35.8557 147.73 36.2098 147.603 36.5958C147.476 36.9819 147.434 37.3909 147.48 37.7948V45.8725H144.684L144.684 32.2539Z"
        fill="white"
      />
      <path
        d="M164.537 41.8726C164.161 44.3448 161.753 46.0415 158.673 46.0415C154.711 46.0415 152.252 43.3871 152.252 39.1286C152.252 34.8569 154.724 32.0864 158.555 32.0864C162.323 32.0864 164.693 34.6747 164.693 38.8039V39.7617H155.074V39.9306C155.03 40.4318 155.092 40.9367 155.258 41.4118C155.424 41.8869 155.689 42.3213 156.035 42.6862C156.382 43.051 156.802 43.338 157.268 43.5279C157.734 43.7179 158.235 43.8066 158.738 43.7881C159.398 43.85 160.061 43.697 160.628 43.3519C161.194 43.0067 161.635 42.4879 161.883 41.8726L164.537 41.8726ZM155.087 37.808H161.896C161.921 37.3573 161.853 36.9064 161.695 36.4834C161.538 36.0604 161.295 35.6745 160.981 35.3499C160.667 35.0254 160.29 34.7691 159.872 34.5973C159.455 34.4255 159.007 34.3418 158.555 34.3515C158.1 34.3488 157.649 34.4363 157.228 34.6089C156.807 34.7815 156.424 35.0358 156.101 35.3571C155.779 35.6785 155.523 36.0605 155.349 36.4812C155.175 36.9018 155.086 37.3528 155.087 37.808Z"
        fill="white"
      />
      <path
        d="M56.8986 13.1333C57.4847 13.0912 58.0727 13.1797 58.6204 13.3925C59.1681 13.6052 59.6618 13.9369 60.0658 14.3635C60.4698 14.7901 60.7741 15.3011 60.9567 15.8596C61.1393 16.4181 61.1957 17.0101 61.1218 17.593C61.1218 20.4604 59.5721 22.1086 56.8986 22.1086H53.6566V13.1333H56.8986ZM55.0506 20.8393H56.7429C57.1617 20.8644 57.5808 20.7957 57.9696 20.6383C58.3585 20.481 58.7075 20.2389 58.991 19.9297C59.2745 19.6205 59.4855 19.2519 59.6087 18.8509C59.7318 18.4498 59.764 18.0263 59.7028 17.6113C59.7595 17.1979 59.7241 16.7771 59.5991 16.3789C59.4741 15.9808 59.2625 15.6153 58.9796 15.3086C58.6968 15.0018 58.3495 14.7614 57.9628 14.6046C57.5761 14.4478 57.1595 14.3785 56.7429 14.4016H55.0506V20.8393Z"
        fill="white"
      />
      <path
        d="M62.6966 18.7189C62.654 18.2738 62.7049 17.8247 62.8461 17.4004C62.9872 16.9761 63.2155 16.586 63.5162 16.2551C63.817 15.9242 64.1836 15.6598 64.5925 15.4789C65.0014 15.298 65.4437 15.2045 65.8908 15.2045C66.338 15.2045 66.7802 15.298 67.1891 15.4789C67.598 15.6598 67.9646 15.9242 68.2654 16.2551C68.5661 16.586 68.7944 16.9761 68.9356 17.4004C69.0767 17.8247 69.1276 18.2738 69.0851 18.7189C69.1284 19.1645 69.0781 19.6142 68.9374 20.0392C68.7966 20.4642 68.5684 20.855 68.2676 21.1865C67.9668 21.5181 67.5999 21.783 67.1906 21.9643C66.7812 22.1457 66.3385 22.2393 65.8908 22.2393C65.4431 22.2393 65.0004 22.1457 64.5911 21.9643C64.1817 21.783 63.8149 21.5181 63.514 21.1865C63.2132 20.855 62.9851 20.4642 62.8443 20.0392C62.7035 19.6142 62.6532 19.1645 62.6966 18.7189ZM67.7101 18.7189C67.7101 17.2507 67.0505 16.3921 65.893 16.3921C64.7311 16.3921 64.0774 17.2507 64.0774 18.7189C64.0774 20.1989 64.7311 21.0509 65.893 21.0509C67.0506 21.0509 67.7101 20.193 67.7101 18.7189Z"
        fill="white"
      />
      <path
        d="M77.5771 22.1085H76.1904L74.7905 17.1199H74.6847L73.2907 22.1085H71.9172L70.0502 15.3352H71.406L72.6194 20.5036H72.7193L74.1118 15.3352H75.3942L76.7868 20.5036H76.8926L78.1 15.3352H79.4368L77.5771 22.1085Z"
        fill="white"
      />
      <path
        d="M81.0071 15.3352H82.2939V16.4112H82.3938C82.5632 16.0247 82.849 15.7007 83.2114 15.4844C83.5737 15.2681 83.9945 15.1702 84.4151 15.2044C84.7446 15.1796 85.0756 15.2293 85.3833 15.3498C85.6911 15.4703 85.9678 15.6585 86.1929 15.9004C86.4181 16.1424 86.5859 16.4319 86.684 16.7475C86.7821 17.0631 86.8079 17.3967 86.7595 17.7237V22.1084H85.4228V18.0593C85.4228 16.9708 84.9498 16.4295 83.9612 16.4295C83.7374 16.4191 83.514 16.4572 83.3064 16.5412C83.0987 16.6251 82.9116 16.753 82.758 16.9161C82.6044 17.0791 82.4879 17.2734 82.4164 17.4857C82.3449 17.698 82.3201 17.9232 82.3438 18.146V22.1085H81.0071L81.0071 15.3352Z"
        fill="white"
      />
      <path d="M88.8895 12.691H90.2263V22.1085H88.8895V12.691Z" fill="white" />
      <path
        d="M92.0845 18.7189C92.0419 18.2738 92.0929 17.8246 92.2341 17.4003C92.3753 16.976 92.6036 16.5859 92.9043 16.255C93.2051 15.9241 93.5718 15.6597 93.9807 15.4788C94.3897 15.2978 94.8319 15.2044 95.2791 15.2044C95.7263 15.2044 96.1685 15.2978 96.5775 15.4788C96.9864 15.6597 97.353 15.9241 97.6538 16.255C97.9546 16.5859 98.1829 16.976 98.3241 17.4003C98.4653 17.8246 98.5162 18.2738 98.4737 18.7189C98.517 19.1645 98.4667 19.6142 98.3258 20.0392C98.185 20.4642 97.9568 20.855 97.656 21.1865C97.3551 21.5181 96.9882 21.783 96.5789 21.9643C96.1695 22.1456 95.7268 22.2393 95.2791 22.2393C94.8314 22.2393 94.3886 22.1456 93.9793 21.9643C93.5699 21.783 93.203 21.5181 92.9022 21.1865C92.6013 20.855 92.3731 20.4642 92.2323 20.0392C92.0915 19.6142 92.0411 19.1645 92.0845 18.7189ZM97.098 18.7189C97.098 17.2507 96.4384 16.3921 95.2809 16.3921C94.119 16.3921 93.4653 17.2507 93.4653 18.7189C93.4653 20.1989 94.119 21.0509 95.2809 21.0509C96.4385 21.0509 97.098 20.193 97.098 18.7189Z"
        fill="white"
      />
      <path
        d="M99.881 20.193C99.881 18.9738 100.789 18.2709 102.4 18.171L104.235 18.0652V17.4806C104.235 16.7652 103.762 16.3612 102.848 16.3612C102.102 16.3612 101.585 16.6352 101.437 17.1141H100.142C100.279 15.9507 101.373 15.2044 102.91 15.2044C104.608 15.2044 105.566 16.0498 105.566 17.4806V22.1085H104.279V21.1566H104.173C103.959 21.4981 103.657 21.7764 103.3 21.9633C102.942 22.1501 102.542 22.2388 102.139 22.2201C101.854 22.2497 101.567 22.2194 101.295 22.1311C101.023 22.0428 100.773 21.8985 100.56 21.7075C100.347 21.5165 100.177 21.2831 100.06 21.0222C99.9431 20.7614 99.8821 20.4789 99.881 20.193ZM104.235 19.6142V19.048L102.581 19.1537C101.648 19.2161 101.225 19.5334 101.225 20.1306C101.225 20.7402 101.754 21.0949 102.481 21.0949C102.694 21.1165 102.909 21.095 103.114 21.0317C103.319 20.9684 103.508 20.8645 103.672 20.7264C103.836 20.5882 103.97 20.4186 104.066 20.2275C104.163 20.0365 104.22 19.8279 104.235 19.6142Z"
        fill="white"
      />
      <path
        d="M107.323 18.7189C107.323 16.5786 108.423 15.2228 110.134 15.2228C110.558 15.2033 110.978 15.3047 111.346 15.5151C111.713 15.7255 112.014 16.0363 112.211 16.4111H112.311V12.691H113.648V22.1085H112.367V21.0384H112.261C112.048 21.4106 111.738 21.7175 111.363 21.9259C110.988 22.1342 110.563 22.2359 110.134 22.2201C108.411 22.2202 107.323 20.8643 107.323 18.7189ZM108.704 18.7189C108.704 20.1555 109.381 21.02 110.513 21.02C111.64 21.02 112.336 20.1431 112.336 18.7248C112.336 17.3131 111.633 16.4237 110.513 16.4237C109.388 16.4237 108.704 17.294 108.704 18.7189Z"
        fill="white"
      />
      <path
        d="M119.179 18.7189C119.136 18.2738 119.187 17.8247 119.328 17.4004C119.469 16.9761 119.698 16.586 119.998 16.2551C120.299 15.9242 120.666 15.6598 121.075 15.4789C121.484 15.298 121.926 15.2045 122.373 15.2045C122.82 15.2045 123.262 15.298 123.671 15.4789C124.08 15.6598 124.447 15.9242 124.747 16.2551C125.048 16.586 125.277 16.9761 125.418 17.4004C125.559 17.8247 125.61 18.2738 125.567 18.7189C125.611 19.1645 125.56 19.6142 125.419 20.0392C125.279 20.4642 125.051 20.855 124.75 21.1865C124.449 21.5181 124.082 21.783 123.673 21.9643C123.263 22.1457 122.821 22.2393 122.373 22.2393C121.925 22.2393 121.482 22.1457 121.073 21.9643C120.664 21.783 120.297 21.5181 119.996 21.1865C119.695 20.855 119.467 20.4642 119.326 20.0392C119.186 19.6142 119.135 19.1645 119.179 18.7189ZM124.192 18.7189C124.192 17.2507 123.533 16.3921 122.375 16.3921C121.213 16.3921 120.56 17.2507 120.56 18.7189C120.56 20.1989 121.213 21.0509 122.375 21.0509C123.533 21.0509 124.192 20.193 124.192 18.7189Z"
        fill="white"
      />
      <path
        d="M127.361 15.3352H128.648V16.4112H128.747C128.917 16.0247 129.203 15.7007 129.565 15.4844C129.927 15.2681 130.348 15.1702 130.769 15.2044C131.098 15.1796 131.429 15.2293 131.737 15.3498C132.045 15.4703 132.321 15.6585 132.547 15.9004C132.772 16.1424 132.94 16.4319 133.038 16.7475C133.136 17.0631 133.162 17.3967 133.113 17.7237V22.1084H131.776V18.0593C131.776 16.9708 131.303 16.4295 130.315 16.4295C130.091 16.4191 129.868 16.4572 129.66 16.5412C129.452 16.6251 129.265 16.753 129.112 16.9161C128.958 17.0791 128.841 17.2734 128.77 17.4857C128.699 17.698 128.674 17.9232 128.697 18.146V22.1085H127.361V15.3352Z"
        fill="white"
      />
      <path
        d="M140.667 13.6488V15.366H142.134V16.492H140.667V19.9749C140.667 20.6844 140.959 20.9951 141.624 20.9951C141.795 20.9945 141.965 20.9842 142.134 20.9642V22.0777C141.894 22.1206 141.651 22.1435 141.407 22.146C139.92 22.146 139.328 21.623 139.328 20.3171V16.4919H138.253V15.366H139.328V13.6488H140.667Z"
        fill="white"
      />
      <path
        d="M143.96 12.691H145.285V16.4236H145.391C145.568 16.0336 145.862 15.7078 146.232 15.4906C146.601 15.2735 147.029 15.1756 147.456 15.2103C147.784 15.1925 148.112 15.2472 148.416 15.3706C148.72 15.494 148.993 15.683 149.216 15.9242C149.439 16.1654 149.606 16.4528 149.705 16.7659C149.804 17.0789 149.832 17.41 149.789 17.7354V22.1085H148.451V18.0652C148.451 16.9833 147.947 16.4354 147.002 16.4354C146.772 16.4165 146.541 16.4481 146.325 16.5279C146.109 16.6077 145.913 16.7338 145.75 16.8974C145.588 17.061 145.463 17.258 145.385 17.4749C145.306 17.6917 145.276 17.923 145.297 18.1526V22.1085H143.96L143.96 12.691Z"
        fill="white"
      />
      <path
        d="M157.583 20.2797C157.401 20.8987 157.008 21.4342 156.471 21.7924C155.935 22.1506 155.289 22.3088 154.648 22.2392C154.202 22.251 153.758 22.1656 153.348 21.9888C152.939 21.812 152.572 21.5481 152.274 21.2155C151.977 20.8828 151.755 20.4894 151.624 20.0625C151.494 19.6355 151.458 19.1854 151.519 18.7431C151.46 18.2996 151.496 17.8484 151.626 17.4202C151.757 16.992 151.977 16.5967 152.273 16.2612C152.569 15.9256 152.934 15.6576 153.343 15.4753C153.752 15.293 154.195 15.2006 154.642 15.2044C156.527 15.2044 157.664 16.492 157.664 18.619V19.0854H152.881V19.1603C152.86 19.4089 152.891 19.6591 152.973 19.8949C153.054 20.1307 153.184 20.3468 153.354 20.5295C153.523 20.7122 153.73 20.8574 153.959 20.9558C154.188 21.0542 154.435 21.1035 154.685 21.1008C155.005 21.1392 155.328 21.0816 155.615 20.9354C155.902 20.7891 156.139 20.5609 156.296 20.2796L157.583 20.2797ZM152.881 18.0968H156.302C156.319 17.8695 156.288 17.6411 156.211 17.4265C156.135 17.2118 156.014 17.0155 155.857 16.8503C155.7 16.685 155.51 16.5544 155.3 16.4669C155.089 16.3794 154.863 16.3369 154.635 16.3421C154.404 16.3392 154.174 16.3826 153.96 16.4698C153.746 16.557 153.551 16.6861 153.388 16.8496C153.224 17.0132 153.095 17.2078 153.008 17.422C152.921 17.6362 152.878 17.8656 152.881 18.0968Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_9">
        <rect width="180" height="60.1684" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GetOnAppleAppStore;
