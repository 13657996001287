import { css } from "@emotion/react";
import theme from "../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Navbar = css`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px 10px 40px;
  background-color: #1c0448;
  @media (max-width: 768px) {
    padding: 20px 20px 10px 20px;
  }
`;

export const Logo = css`
  width: 100px;
`;

export const Card = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 20px 0px 20px;
`;

export const FeatureImage = css`
  width: 300px;
  border-radius: 20px;
  max-width: 300px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Title = css`
  font-family: ${theme.header.font};
  font-size: ${theme.header.size};
  line-height: ${theme.header.lineHeight};
  font-weight: 600;
  text-align: center;
`;

export const TextMulticolor = css`
  background: linear-gradient(
    to right,
    #fabed1 0%,
    #eab6ef 25%,
    #bc9af8 50%,
    #63b3ff 75%,
    #80e3ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const DownlodButton = css`
  display: block;
  cursor: pointer;
`;

export const GooglePlayStore = css`
  width: 180px;
`;
