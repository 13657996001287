const theme = {
  font: {
    family: "Open Sans, sans-serif",
    size: "16px",
    lineHeight: "24px",
  },
  header: {
    font: "Crimson Text, sans-serif",
    size: "40px",
    lineHeight: "50px",
  },
  animation: {
    whileHover: 1.05,
    whileTap: 0.95,
  },
};

export default theme;
